// @flow strict
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Page from "../components/Page";
import useSiteMetadata from "../utils/use-site-metadata";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Publication from "../components/Publication";

const Home = ({ data }) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { body: pageBody } = data.mdx;
  const { frontmatter } = data.mdx;
  const {
    title: pageTitle,
    description: pageDescription = "",
    socialImage,
  } = frontmatter;
  const metaDescription = pageDescription || siteSubtitle;
  const socialImageUrl = socialImage?.publicURL;

  return (
    <Layout
      title={siteTitle}
      description={metaDescription}
      socialImage={socialImageUrl}
    >
      <Sidebar isIndex />
      <Page title={pageTitle}>
        <MDXRenderer>{pageBody}</MDXRenderer>
        {data.allResearchYaml.nodes &&
          data.allResearchYaml.nodes.map((node) => (
            <div key={node.id}>
              <Publication {...node}></Publication>
            </div>
          ))}
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query SlugHome {
    allResearchYaml(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        title
        date
        bibtex
        authors {
          name
          website
        }
        links {
          name
          url
        }
        conference {
          name
          url
        }
        type
      }
    }
    mdx(slug: { eq: "home" }) {
      id
      body
      frontmatter {
        title
        date
        socialImage
      }
    }
  }
`;

export default Home;
